exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-fun-js": () => import("./../../../src/pages/fun.js" /* webpackChunkName: "component---src-pages-fun-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-motion-js": () => import("./../../../src/pages/motion.js" /* webpackChunkName: "component---src-pages-motion-js" */),
  "component---src-pages-ux-work-js": () => import("./../../../src/pages/ux-work.js" /* webpackChunkName: "component---src-pages-ux-work-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-ptt-js": () => import("./../../../src/templates/ptt.js" /* webpackChunkName: "component---src-templates-ptt-js" */),
  "component---src-templates-timeline-js": () => import("./../../../src/templates/timeline.js" /* webpackChunkName: "component---src-templates-timeline-js" */)
}

